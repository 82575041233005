export type SupportedLanguage = 'fi' | 'en' | 'sv';
export type LanguageMenuItem = {
  language: SupportedLanguage;
  text: string;
};

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_COUNTRY = 'Sweden';

export const SupportedLanguages: SupportedLanguage[] = ['fi', 'en', 'sv'];

export const supportedLanguagesMenuItem: LanguageMenuItem[] = [
  { language: 'en', text: 'In english' },
  { language: 'fi', text: 'Suomi' },
  { language: 'sv', text: 'På svenska' },
];
