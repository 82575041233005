import React, { createContext, useContext, useEffect, useState } from 'react';
import { HelpEntity } from '../models/ContentfulTypes';

type ModalState = {
  showModal: boolean;
  toggleModal: () => void;
  setEntity: (entity: HelpEntity) => void;
  entity?: HelpEntity;
};

type Props = {
  children: React.ReactNode;
};

const ModalContext = createContext<ModalState>({
  showModal: false,
  toggleModal: () => {
    return;
  },
  setEntity: () => {
    return;
  },
});

export const ModalProvider = ({ children }: Props) => {
  const [showModal, toggleModal] = useState<boolean>(false);
  const [entity, setEntity] = useState<HelpEntity>();

  return (
    <ModalContext.Provider
      value={{
        showModal,
        entity,
        toggleModal: () => toggleModal(!showModal),
        setEntity: (entity: HelpEntity) => setEntity(entity),
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalState = () => useContext(ModalContext);
