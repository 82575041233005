import React, { createContext, useContext, useEffect, useState } from 'react';
import { DEFAULT_LANGUAGE } from '../constants/language';

type LanguageState = {
  setLanguage: (lang: string) => void;
  language?: string;
};

type Props = {
  children: React.ReactNode;
};

const LanguageContext = createContext<LanguageState>({
  setLanguage: () => {
    return;
  },
  language: DEFAULT_LANGUAGE,
});

export const LanguageProvider = ({ children }: Props) => {
  const [language, setLanguage] = useState<string>();

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage: (language: string) => setLanguage(language),
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageState = () => useContext(LanguageContext);
