import React from 'react';

import { ModalProvider } from './src/context/ModalContext';
import { LanguageProvider } from './src/context/LanguageContext.tsx';

export const wrapRootElement = ({ element }) => {
  return (
    <LanguageProvider>
      <ModalProvider>{element}</ModalProvider>
    </LanguageProvider>
  );
};
